<script setup lang="ts">
definePageMeta({
	layout: 'auth'
})

const toast = useToast()

const { session: _session } = useUserSession()

useSeoMeta({
	title: 'ConsigPro v2'
})

const fields = [{
	name: 'email',
	type: 'text',
	label: 'Email',
	placeholder: 'Digite seu e-mail'
}, {
	name: 'password',
	label: 'Senha',
	type: 'password',
	placeholder: 'Digite sua senha'
}]

interface FormError<T> {
	path: T
	message: string
}
const loadingSubmit = ref(false)
const validate = async (state: any): Promise<FormError<string>[]> => {
	loadingSubmit.value = true
	const errors: FormError<string>[] = []
	if (!state.email) errors.push({ path: 'email', message: 'Digite um email' })
	if (!state.password) errors.push({ path: 'password', message: 'Digite uma senha' })
	if (errors.length) {
		/* toast.add({
      title: 'Error',
      description: errors[0].message,
      color: 'red'
    }) */
		errors.push({ path: 'password', message: errors[0]?.message || '' })
	}
	const { data, error } = await useFetch('/api/login', {
		method: 'POST',
		body: {
			email: state.email,
			password: state.password
		}
	})
	if (error?.value?.statusCode === 400) {
		toast.add({
			title: 'Erro',
			icon: 'i-heroicons-exclamation-circle-20-solid',
			description: error.value.data.message,
			color: 'red',
			timeout: 6000
		})
	} else if (error?.value?.statusCode === 500) {
		toast.add({
			title: 'Erro',
			icon: 'i-heroicons-exclamation-circle-20-solid',
			description: 'Erro ao chamar o servidor. Por favor, contacte o suporte de TI.',
			color: 'red',
			timeout: 10000
		})
	}
	if ((data.value as any)?.success) {
		navigateTo('/')
	}
	loadingSubmit.value = false
	return errors
}

const img = useImage()
</script>

<template>
	<UCard class="max-w-sm w-full bg-white/75 dark:bg-white/5 backdrop-blur">
		<UAuthForm
			:fields="fields"
			:validate="validate"
			align="top"
			:ui="{ base: 'text-center', footer: 'text-center' }"
			:submit-button="{ trailingIcon: 'i-heroicons-arrow-right-20-solid', loading: loadingSubmit, label: 'Continuar' }"
		>
			<template #title>
				<RadiantText class="select-none">
					ConsigPro <i>v2</i>
				</RadiantText>
			</template>
			<template #icon>
				<NuxtImg
					:placeholder="img(`/logo_nibb_finance.svg`, { h: 10, f: 'png', blur: 2, q: 50 })"
					class="flex justify-center w-20 h-20 mx-auto select-none"
					src="/logo_nibb_finance.svg"
					alt="Company Logo"
				/>
			</template>
			<template #description>
				<span class="select-none">Bem vindo(a) de volta!</span>
			</template>

			<!--      <template #password-hint>
        <NuxtLink
          to="/"
          class="text-primary font-medium"
        >Forgot password?
        </NuxtLink>
      </template> -->

			<template #footer>
				Ao fazer login, você está de acordo com nossos
				<NuxtLink
					to="/"
					class="text-primary font-medium"
				>Termos de Serviço
				</NuxtLink>
				.
			</template>
		</UAuthForm>
	</UCard>
</template>
